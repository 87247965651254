.app {
  min-height: 100vh;
  background-color: black;
}

.app-header {
  padding: 0.1em 0.2em;
  font-size: 5em;
  color: white;
  position: absolute;
  font-weight: normal;
  left: 0;
  top:0;
  z-index: 10;
}

.app-footer {
  padding: 0.1em 0.2em;
  font-size: 1em;
  color: white;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
}

canvas {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top:0;
  z-index: 1;
}

.control-group {
  padding: 2em;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 10;
}

.control {
  margin: 0.5em;
}

.bp3-intent-primary {
  background-color: #9ca5ac !important;
}
